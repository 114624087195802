import cookies from 'js-cookie';
import { NextRouter } from 'next/router';
import { Geopoint, LightLocation, Location } from 'pleinchamp-api-client';
import { useRef, useState } from 'react';
import { DateType } from '@date-io/type';
import { City, ForecastAdditionalInfo, MoonPhase, PlcGeoFeature, StandardCity } from '@meteo/types';
import { usePlcRouter } from '@utils/customHooks';
import { plcDayjs } from '@utils/date';
import { isServer } from '@utils/server';
import { safeJsonParse } from '@utils/strings';
import { buildTargetUrlWithUpdatedParam, fromCityToUrlSlug } from '@utils/url';
import { PlcNextPageContext } from 'features/business/app.types';
import nextCookie from 'next-cookies';
import SunCalc from 'suncalc';

function isBetween(val: number, min: number, max: number) {
  return val > min && val <= max;
}

function getMoonPhaseFromPercent(percent: number) {
  if (isBetween(percent, 0.0625, 0.1875)) {
    return MoonPhase.WaxingCrescent;
  }
  if (isBetween(percent, 0.1875, 0.3125)) {
    return MoonPhase.FirstQuarter;
  }
  if (isBetween(percent, 0.3125, 0.4375)) {
    return MoonPhase.WaxingGibbous;
  }
  if (isBetween(percent, 0.4375, 0.5625)) {
    return MoonPhase.FullMoon;
  }
  if (isBetween(percent, 0.5625, 0.6875)) {
    return MoonPhase.WaningGibbous;
  }
  if (isBetween(percent, 0.6875, 0.8125)) {
    return MoonPhase.LastQuarter;
  }
  if (isBetween(percent, 0.8125, 0.9377)) {
    return MoonPhase.WaningCrescent;
  }
  return MoonPhase.NewMoon;
}

export function computeMeteoInfoFromDateAndPostion(date: Date, lat: number, lon: number): ForecastAdditionalInfo {
  // Be sure to get the sunset and sunrise for the good date as suncalc use utc
  const utcDate = plcDayjs(date)
    .subtract(date.getTimezoneOffset(), 'minute')
    .toDate();
  const times = SunCalc.getTimes(utcDate, lat, lon);
  const nightTime = new Date(date);
  nightTime.setHours(23, 59);
  const moonPercent = SunCalc.getMoonIllumination(nightTime).phase;
  return {
    moonPhase: getMoonPhaseFromPercent(moonPercent),
    sunrise: times.sunrise,
    sunset: times.sunset,
  };
}

export function fromFeatureToCity(feature: PlcGeoFeature): City {
  const geometry: Geopoint = {
    latitude: feature.geometry.coordinates[1],
    longitude: feature.geometry.coordinates[0],
  };
  return {
    ...feature,
    geometry,
  };
}

export function fromLocationToCity(location: Location): StandardCity {
  return {
    geometry: location.geopoint,
    properties: {
      citycode: location.id.toString(),
      name: location.address.city,
      postcode: location.address.zipPostcode || '',
    },
    type: 'Feature',
  };
}

export function fromCityToLightLocation(city?: City): LightLocation | null {
  if (!city) return null;

  const { geometry, properties } = city;

  if (!geometry) return null;

  return {
    address: {
      city: properties.city ? properties.city : '',
      country: 'France',
      countryCode: 'fr',
      line1: properties.label ? properties.label : '',
      line2: '',
      line3: '',
      // not return from api-gouv
      stateOrProvince: properties.city ? properties.city : '',
      stateOrProvinceCode: properties.postcode.substr(0, 2),
      zipPostcode: properties.postcode,
    },
    geopoint: {
      latitude: geometry.latitude,
      longitude: geometry.longitude,
    },
  };
}

export function isGeolocation(city: City) {
  return city.type === 'Geolocation';
}

export function getLastCity(ctx?: PlcNextPageContext): City | undefined {
  let lastMeteoCity: string | undefined;
  if (ctx) {
    lastMeteoCity = nextCookie(ctx).lastMeteoCity;
  } else if (!isServer()) {
    lastMeteoCity = cookies.get('lastMeteoCity');
  }
  return lastMeteoCity ? safeJsonParse(lastMeteoCity) : undefined;
}

export function setLastCity(city: City) {
  cookies.set('lastMeteoCity', JSON.stringify(city), { expires: 365, sameSite: 'strict' });
}

export function getAndRemoveCityToAdd() {
  if (isServer()) {
    return undefined;
  }
  const cityToAdd = localStorage.getItem('plc-add-city');
  localStorage.removeItem('plc-add-city');
  return cityToAdd ? (safeJsonParse(cityToAdd) as City) : undefined;
}

export function setCityToAdd(city: City) {
  if (!isServer()) {
    localStorage.setItem('plc-add-city', JSON.stringify(city));
  }
}

export function useSelectNewCity(href?: string, defaultCity?: City) {
  const router = usePlcRouter();
  const firstRun = useRef(true);
  const [selectedCity, setSelectedCity] = useState(defaultCity || getLastCity());

  // default city may be retrieved from url on server side and cookie creation is not permitted on SSR so we put it now
  if (firstRun.current) {
    if (defaultCity) {
      setLastCity(defaultCity);
    }
    firstRun.current = false;
  }

  function onSelectNewCity(newCity?: City) {
    if (!newCity) return;
    if (!selectedCity || newCity.properties.citycode !== selectedCity.properties.citycode) {
      setSelectedCity(newCity);
      if (newCity) {
        setLastCity(newCity);
      }
      const { asPath, query } = router;
      if (!href) return;
      const newUrl = buildTargetUrlWithUpdatedParam(href, 'locality', fromCityToUrlSlug(newCity), query);
      if (asPath !== newUrl) {
        router.replace(href, newUrl, {
          shallow: true,
        });
      }
    }
  }

  return { onSelectNewCity, selectedCity, setSelectedCity };
}

export type CityInfos = {
  departmentCode: string;
  departmentLabel: string;
  city: string;
  postCode: string;
};

export function getCityInfos(city: StandardCity): CityInfos | undefined {
  if (!city.properties.context || !city.properties.city) {
    return undefined;
  }

  const contextMatch = /^([0-9]{2})([0-9]*), ([^,]*)/;
  const { context, postcode: postCode, city: cityName } = city.properties;
  const matches = context.match(contextMatch);

  if (!matches) {
    return undefined;
  }

  const infos: CityInfos = {
    city: cityName,
    departmentCode: matches[1],
    departmentLabel: matches[3],
    postCode,
  };
  return infos;
}

export function getCityCanonical(router: NextRouter, city = getLastCity()) {
  if (!city || !city.properties.city) {
    return undefined;
  }

  const { pathname, query } = router;

  return buildTargetUrlWithUpdatedParam(
    pathname.includes('locality') ? pathname : `${pathname}/[locality]`,
    'locality',
    fromCityToUrlSlug(city, true, false),
    query
  );
}

export function isItNight(sunrise?: Date, sunset?: Date, date?: DateType) {
  if (!sunrise || !sunset) {
    return false;
  }
  const comparedDate = plcDayjs(date);
  return comparedDate.isAfter(sunset) || comparedDate.isBefore(sunrise);
}
