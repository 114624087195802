import kebabCase from 'lodash/kebabCase';
import trim from 'lodash/trim';
import { LinkProps } from 'next/link';
import { NextRouter, SingletonRouter } from 'next/router';
import { ActivityCode, ContactSubject, InterestCode } from 'pleinchamp-api-client';
import { ReactText } from 'react';
import { isGeolocation } from '@meteo/business/meteo.utils';
import { City } from '@meteo/types';
import { activityInUrl, contentTypeInUrl, interestInUrl, SearchContentType } from '@search/business/search.types';
import { SettingsTabEnum } from '@settings/business/Settings.type';
import { ApiStatusCode } from '@utils/api.utils';
import { PlcNextPageContext } from 'features/business/app.types';
import { defaultNamespaces } from './strings';

export enum urlEnum {
  journal = '/',
  vraijournal = '/recherche?type_de_contenu=tous',
  actu = '/actualite',
  event = '/evenement',
  meteo = '/meteo',
  hourByHour = '/meteo/heure-par-heure',
  meteoRadar = '/meteo/detail-radar',
  communityForecastForm = '/meteo/meteo-participative',
  meteoPlv = '/meteo/affichage-cr',
  search = '/recherche',
  activities = '/activites',
  interests = '/centres-d-interets',
  services = '/les-outils',
  innovations = '/les-innovations',
  partners = '/nos-partenaires',
  countrySheets = '/les-outils/fiches-pays-vin',
  treatmentSchedule = '/les-outils/planning-de-traitement',
  meadow = '/les-outils/production-des-prairies',
  herbDrying = '/les-outils/assechement-de-l-herbe',
  stocks = '/les-cours',
  stocksTerm = '/les-cours/marches-a-terme',
  notifications = '/mon-compte/notifications',
  profile = '/mon-compte/profil',
  preferences = '/mon-compte/preferences',
  welcome = '/mon-compte/bienvenue',
  validateTos = '/mon-compte/valider-cgu',
  login = '/connexion',
  signUp = '/inscription',
  signUpPro = '/inscription-pro',
  subscriptionPro = '/mon-compte/abonnement-pro',
  landingPro = '/decouvrir-pleinchamp-pro',
  proSavoirPlus = '/actualite/acces-pleinchamp-pro-exclusivite-credit-agricole',
  faq = '/faq',
  tos = '/CGU',
  privacyPolicy = '/protection-des-donnees',
  legalNotice = '/a-propos',
  cookies = '/cookies',
  sitemap = '/plan-du-site',
  advertisement = '/publicites',
  contactUs = '/formulaire-de-contact',
  resetPassword = '/reinitialisation-mot-de-passe',
  guides = '/les-guides',
  forecastTransparency = '/page-transparence',
  atriumSSO = '/atrium-sso',
  preview = '/preview',
  espaceAssurance = '/espace-assurance',
  meteoAgricoleSurMesure = '/actualite/votre-meteo-agricole-sur-mesure',
  productionDesPrairies = '/les-outils/production-des-prairies/production-cumulee',
  personnalisationMeteo = '/actualite/tuto-6-comment-personnaliser-sa-meteo',
  game = '/actualite/reglement-jeu-pleinchamp-sia-2023',
}

export function transformUrlParamToEnumArray<T>(
  input: string | string[] | undefined,
  argEnum: { [key: string]: string | number }
) {
  let formatedArray: T[] = [];
  if (input) {
    const inputAsArray = typeof input === 'string' ? input.split(',') : input;

    formatedArray = inputAsArray.reduce((formated: T[], a) => {
      if (Object.values(argEnum).includes(a)) {
        formated.push((a as unknown) as T);
      }
      return formated;
    }, []);
  }
  return formatedArray;
}

export function transformUrlParamToEnum<T>(
  input: string | string[] | undefined,
  argEnum: { [key: string]: string | number }
) {
  if (input && typeof input === 'string') {
    if (Object.values(argEnum).includes(input)) {
      return (input as unknown) as T;
    }
  }
  return undefined;
}

type UrlParam = string | string[] | undefined;
export type UrlParams = { [key: string]: UrlParam };

export function formatUrlWithParam(url: string, params: UrlParams) {
  const queryParams = Object.keys(params)
    // Remove undefined or empty params
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .filter(key => !!params[key] && params[key]!.length > 0)
    .reduce((result: string[], key) => {
      // Transform string[] as string
      const value = typeof params[key] === 'object' ? (params[key] as string[]).join(',') : (params[key] as string);
      result.push(`${key}=${value}`);
      return result;
    }, []);

  const paramsAsString = `${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`;
  return `${url}${paramsAsString}`;
}

export function castUrlParamToString(param: UrlParam) {
  if (param) {
    return typeof param === 'string' ? param : param.join('');
  }
  return param;
}

type AsUrlParam = ReactText;
export interface AsUrlParams {
  [param: string]: AsUrlParam | AsUrlParam[];
}
export function getAs(url: string, params: AsUrlParams, forceKebabCase = true) {
  const paramCopy = { ...params };
  const regex = /\[([^\]]*)\]/g;
  const matches = url.match(regex);
  let as = url;

  if (matches) {
    matches.forEach(match => {
      const paramName = trim(match, '[]');

      if (params[paramName]) {
        delete paramCopy[paramName];
        const param = Array.isArray(params[paramName]) ? (params[paramName] as []).join('-') : `${params[paramName]}`;
        const formattedParam = forceKebabCase ? kebabCase(param) : param;
        as = as.replace(match, formattedParam);
      }
    });
  }

  // If some params were not used in the url, we set them as query params
  const queryKeys = Object.keys(paramCopy);
  if (queryKeys && queryKeys.length) {
    as += '?';
    as += queryKeys.reduce(
      (acc, key) => (paramCopy[key] ? `${acc}${key}=${encodeURI(paramCopy[key].toString())}&` : acc),
      ''
    );
  }

  return as;
}

export function getLinkProps(url: string, params: AsUrlParams, forceKebabCase = true) {
  return {
    as: getAs(url, params, forceKebabCase),
    href: url,
  };
}

export function getSlugLinkProps(url: urlEnum, slug: string) {
  return getLinkProps(`${url}/[slug]`, { slug }, false);
}

export function getPreferencesLinkProps(prefType: SettingsTabEnum) {
  return getLinkProps(`${urlEnum.preferences}/[prefType]`, { prefType });
}

export function returnError(errorCode: ApiStatusCode) {
  return { errorCode, namespacesRequired: defaultNamespaces };
}

export function return404(ctx: PlcNextPageContext) {
  ctx!.res!.statusCode = ApiStatusCode.notFound;
  return returnError(ApiStatusCode.notFound);
}

export function redirect(params: {
  Router: SingletonRouter;
  ctx: PlcNextPageContext;
  location: string;
  status?: number;
}) {
  const { Router, ctx, location, status = ApiStatusCode.redirect } = params;

  if (ctx.res) {
    ctx.res.writeHead(status, {
      'Content-Type': 'text/html; charset=utf-8',
      Location: location,
    });
    ctx.res.end();
    return;
  }

  Router.replace(location);
}

export function formatPostcodeAndName(postcode: string, name: string) {
  return `${postcode}-${kebabCase(name).replace('-e-', 'e-')}`;
}

export function fromCityToUrlSlug(city: City, forceCity = false, includeMyPosition = true) {
  // eslint-disable-next-line prefer-destructuring
  let name = city.properties.name;
  if (forceCity && city.properties.city) {
    name = city.properties.city;
  }
  return isGeolocation(city) && includeMyPosition
    ? 'ma-position'
    : formatPostcodeAndName(city.properties.postcode, name);
}

export function buildTargetUrlWithUpdatedParam(
  href: string,
  updatedParam: string,
  updatedParamValue: string,
  query: NextRouter['query']
) {
  return href
    .split('/')
    .map(urlFragment => {
      if (urlFragment.length >= 3 && urlFragment[0] === '[') {
        const param = urlFragment.substr(1, urlFragment.length - 2);
        if (param === updatedParam) {
          return encodeURI(updatedParamValue);
        }
        return query[param];
      }
      return urlFragment;
    })
    .join('/');
}

export function isExternal(href: LinkProps['href']) {
  return typeof href === 'string' && /^https?:/.test(href);
}

export function getTransversalLinkProps(as: string) {
  return {
    as,
    href: '/[transversal]',
  };
}

export function getAllContentTypeLinkProps(
  searchContentType: SearchContentType,
  interests?: InterestCode[],
  activities?: ActivityCode[]
) {
  const query = {
    type_de_contenu: contentTypeInUrl[searchContentType],
  };
  if (interests) {
    // @ts-ignore
    query.centres_d_interets = interests.map(i => interestInUrl[i]).join(',');
  }
  if (activities) {
    // @ts-ignore
    query.activites = activities.map(i => activityInUrl[i]).join(',');
  }
  return {
    pathname: urlEnum.search,
    query,
  };
}

export function getContactHref(subject: ContactSubject, message: string) {
  return {
    pathname: urlEnum.contactUs,
    query: { message, subject },
  };
}
